.dashboard
  display: block
  position: relative
  width: calc(100% - 10px)
  height: auto
  min-height: 300px
  margin-top: 20px
  padding: 5px
  div
    display: inline-block
    position: relative
    vertical-align: top
    width: calc(100% - 18px)
    height: auto
    min-height: 200px
    border-radius: 12px
    overflow: hidden
    margin: 8px
    box-shadow: -1px 0px 25px 0px rgba(0,0,0,0.1)
    background: rgba(229, 229, 229, 0.65)
